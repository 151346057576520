function detectAdblock(callback) {
    fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js', {
        method: 'HEAD',
        mode: 'no-cors',
    }).then((response) => {
        // If the request is redirected, then the ads are blocked.
        callback(response.redirected)
    }).catch(() => {
        // If the request fails completely although connected to the internet,
        // then the ads are blocked.
        callback(window.navigator.onLine)
    })
}

document.addEventListener('DOMContentLoaded', function () {
    detectAdblock((adBlockEnabled) => {
        if (adBlockEnabled) {
            Livewire.dispatch('adBlockDetected');
        }
    });
});

export default function (Alpine) {
    Alpine.data('googleAdManager', ($wire) => ({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        resizeTimeout: null,
        config: null,
        targeting: null,

        init() {
            this.config = $wire.config;
            this.targeting = $wire.targeting;

            window.addEventListener('resize', () => {
                clearTimeout(this.resizeTimeout);

                this.resizeTimeout = setTimeout(() => {
                    let newWindowWidth = window.innerWidth;
                    let newWindowHeight = window.innerHeight;

                    let widthDifference = Math.abs(newWindowWidth - this.windowWidth);
                    let heightDifference = Math.abs(newWindowHeight - this.windowHeight);

                    if (widthDifference > 30 || heightDifference > 100) {
                        this.windowWidth = newWindowWidth;
                        this.windowHeight = newWindowHeight;
                        this.refreshAds();
                    }
                }, 400);
            });
        },

        defineSlot(el) {
            const id = el.id;

            googletag.cmd.push(() => {
                const slot = googletag.defineSlot(
                    `/${this.config.gamNetworkCode}/${this.config.adUnit}`,
                    this.config.sizes.sizes,
                    id
                );

                const sizeMapping = googletag.sizeMapping();
                Object.values(this.config.sizes.sizeMapping).forEach(mapping => {
                    sizeMapping.addSize(
                        [mapping.viewport[0], mapping.viewport[1]],
                        mapping.sizes
                    );
                });

                slot.defineSizeMapping(sizeMapping.build());

                Object.keys(this.targeting).forEach(key => {
                    slot.setTargeting(key, this.targeting[key]);
                });

                slot.setCollapseEmptyDiv(true, true);
                slot.addService(googletag.pubads());

                googletag.display(id);
            });
        },

        refreshAds() {
            googletag.cmd.push(() => {
                googletag.pubads().refresh();
            });
        },

    }));
}

